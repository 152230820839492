.help-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.help-box-icon {
    cursor: pointer;
    filter: brightness(0.5);
    transition: 0.25s ease-out;
}

.help-box-box {
    position: absolute;
    left: 25px;
    width: 250px;
    text-align: center;
    background-color: var(--color-bg);
    padding: 10px;
    border-left: 1px solid var(--color-a1-light);
    border-bottom: 1px solid var(--color-a1-light);
    visibility: hidden;
    opacity: 0;
    transition: 0.25s ease-out;
    z-index: 10;
}

.help-box:hover .help-box-box {
    visibility: visible;
    opacity: 1;
}

.help-box:hover .help-box-icon {
    filter: brightness(1);
    color: var(--color-a1-light);
}