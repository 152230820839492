.wordle.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.wordle.input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 0 0 50%;
}

.wordle.input-title {
    font-size: 23px;
}

.wordle.known-letters-title {
    font-size: 17px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.wordle.known-letter-input {
    font-size: 20px;
}

.wordle.help {
    padding-left: 10px;
    font-size: 17px;
    position: relative;
    opacity: .5;
    cursor: pointer;
    transition: .25s ease-out;
}

.wordle.help:hover {
    opacity: 1;
}

.wordle.help-box {
    position: absolute;
    bottom: 25px;

    display: none;
    align-items: center;
    justify-content: center;

    overflow-x: initial;
    overflow-y: scroll;

    padding: 10px;

    font-family: Renogare;
    font-weight: lighter;
    text-align: center;

    white-space: normal;

    width: 200px;
    height: 75px;

    background-color: var(--color-bg);
    border: 1px solid var(--color-a1-light);
    border-radius: 5px;

    transform: scale(0);

    transition: 0.5s ease-out;
}

.wordle.help:hover .wordle.help-box {
    display: flex;
    transform: scale(1);
}

.wordle.words-list-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 0 0 50%;
}

@media (max-width: 1300px) {
    .wordle.words-list-container {
        flex: 0 0 25% !important;
    }
    
    .wordle.container {
        justify-content: space-between;
    }
}

.wordle.words-list-title {
    color: var(--color-a1-light);
    border-bottom: 2px solid var(--color-a1-light);
    font-size: 22px;
}

.wordle.words-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1 0;
    overflow-y: scroll;
    max-height: 80vh;
    width: 100%;
}

.wordle.words-list-item {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-a2-light);
    padding: 5px;
    transition: .25s ease-out;
}

.wordle.words-list-item:hover {
    background-color: var(--color-a2-dark);
}

.wordle.words-list-item-probability {
    font-family: bank;
}