/* ==================== All the basics ==================== */

/* ========== Variables ========== */
:root {
    --color-bg: #121212;
    --color-text: #f4e9cd;
    
    --color-a1-dark: #a32f2b;
    --color-a1-light: #ff6f59;
    
    --color-a2-dark: #254441;
    --color-a2-light: #43aa8b;
}


/* ========== Style overrides ========== */
/* Removing scrollbars */
* {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
::-webkit-scrollbar {
    display: none;
}

/* Changing text highlight */
::selection {
    background: var(--color-a1-light);
    color: var(--color-bg);
}
::-moz-selection {
    background: var(--color-a1-light);
    color: var(--color-bg);
}

/* Removing arrows on number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

/* Normal input overrides */
input {
    outline: 0;
    border: 0;
    background-color: #0004;
    border: 1px solid var(--color-a2-dark);
    padding: 5px 10px;
    color: var(--color-a1-light);
    font-family: Renogare;
}

/* Link overrides */
a {
    color: var(--color-a1-dark);
    transition: .25s ease-out;
}

a:hover {
    color: var(--color-a1-light);
}


/* ========== Fonts ========== */
@font-face {
    font-family: Alagard;
    src: url("./assets/fonts/alagard.ttf");
}

@font-face {
    font-family: Renogare;
    src: url("./assets/fonts/Renogare.otf");
}

@font-face {
    font-family: Avalors;
    src: url("./assets/fonts/Avalors.otf");
}

@font-face {
    font-family: Squid;
    src: url("./assets/fonts/Game\ Of\ Squids.ttf");
}

@font-face {
    font-family: Bank;
    src: url("./assets/fonts/Bank\ Printer.ttf");
}





/* ==================== Actual CSS ==================== */

/* ========== Actual for real CSS ========== */
/* Basic body CSS */
body {
    margin: 0;
    padding: 0;
    
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    
    font-family: Renogare;
    
    color: var(--color-text);
    background-color: var(--color-bg);
    
    /* Center align content */
    display: flex;
    align-items: center;
    justify-content: center;
}

#root {
    width: 100%;
    height: 100%;
}
