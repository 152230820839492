.remote.main-container {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.remote-input {
    padding: 10px;
    width: 80%;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.remote-iframe {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    width: 70%;
    height: 80%;
    background-color: #0006;
}
