.letter-position-input-title {
    display: flex;
    border-bottom: 2px solid var(--color-a1-light);
    margin-bottom: 10px;
    padding-bottom: 5px;
    align-items: center;
    justify-content: center;
}

.letter-position-inputs-container {
    width: 450px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 10px;
}

.letter-position-input {
    width: 50px;
    height: 50px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 30px;
    color: var(--color-a2-light);
    border-bottom: 1px solid var(--color-a2-light);
}