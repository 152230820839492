.app {
    width: 100%;
    height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    
    overflow-y: scroll;
}

.main-container {
    flex: 1 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}